import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaTwitter } from "react-icons/fa";

const Header = ({ onHideNav, onShowNav, showNav }) => (
  <Navbar bg="light" expand="lg" style={{ boxShadow: "1px 3px 5px rgba(0,0,0,0.1)" }}>
    <Navbar.Brand>
      <Link to="/">
        <img
          src={require("../images/tbtc-logo.svg")}
          style={{ width: "150px" }}
          alt={`tBTC logo`}
        />
      </Link>
    </Navbar.Brand>

    <Navbar.Toggle className="overline px-2 py-1">Menu</Navbar.Toggle>

    <Navbar.Collapse>
      <Nav>
        <div className="nav-item">
          <Link className="nav nav-link text-decoration-none overline" to="/intro">
            Intro
          </Link>
        </div>
        <div className="nav-item">
          <Link className="nav nav-link text-decoration-none overline" to="/deep-dive">
            Guide
          </Link>
        </div>
        <div className="nav-item">
          <Link className="nav nav-link text-decoration-none overline" to="/whitepaper">
            Whitepaper Walkthrough
          </Link>
        </div>
      </Nav>
      <div className="nav-item ml-auto">
        <Button
          href={`https://twitter.com/intent/tweet?text=tBTC brings pure BTC goodness to Ethereum and DeFi. Check out @rebase's simple guide at https://tbtc.rebase.foundation`}
          className="text-decoration-none"
          variant="light"
        >
          <FaTwitter /> Tweet this out
        </Button>
      </div>
    </Navbar.Collapse>
  </Navbar>
);

export default Header;
