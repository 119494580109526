// import 'bootstrap/dist/css/bootstrap.min.css'
import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { IconContext } from "react-icons";
import "../styles/bootstrap.scss";
import Header from "./header";
import SocialNav from "./SocialNav";

const Layout = ({ children, siteTitle, _rawLogo }) => (
  <IconContext.Provider value={{ style: { verticalAlign: "text-top" } }}>
    <>
      <div className="mb-5 position-sticky" style={{ top: 0, zIndex: 1 }}>
        <Header />
      </div>
      <div>{children}</div>
      <Jumbotron className="text-center">
        <h2>
          Sign up
          <br />
          for the Rebase newsletter
        </h2>
        <Button
          className="text-decoration-none"
          href="https://foundation.us4.list-manage.com/subscribe?u=caae9342f52a16ea36727f89c&id=413d37b977"
        >
          Sign up now
        </Button>
      </Jumbotron>
      <Jumbotron fluid className="mb-0">
        <Container fluid className="d-flex flex-column align-items-center">
          <div className="mb-2">&copy; 2019—{new Date().getFullYear()} Copyright Rebase</div>
          <SocialNav />
        </Container>
      </Jumbotron>
    </>
  </IconContext.Provider>
);

export default Layout;
