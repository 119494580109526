import React from "react";
import Nav from "react-bootstrap/Nav";
import { FaTelegram, FaTwitter, FaYoutube } from "react-icons/fa";

const SocialNav = ({ rightAlign = false }) => {
  return (
    <Nav className={`d-flex ${rightAlign && "ml-auto"}`}>
      <Nav.Link href="https://www.twitter.com/rebasefoundtn" className="text-decoration-none">
        <FaTwitter />
        <span className="d-sm-none"> Twitter</span>
      </Nav.Link>
      {/* <Nav.Link href="https://fb.me/rebasefoundtn" className="text-decoration-none">
        <FaFacebook />
        <span className="d-sm-none"> Facebook</span>
      </Nav.Link> */}
      {/* <Nav.Link href="https://www.linkedin.com/company/31391093" className="text-decoration-none">
        <FaLinkedin />
        <span className="d-sm-none"> LinkedIn</span>
      </Nav.Link> */}
      <Nav.Link
        href="https://www.youtube.com/channel/UC1OfFfqz702tewF5HDXdFtg"
        className="text-decoration-none"
      >
        <FaYoutube />
        <span className="d-sm-none"> YouTube</span>
      </Nav.Link>
      <Nav.Link href="https://t.me/rebasefoundation" className="text-decoration-none">
        <FaTelegram />
        <span className="d-sm-none"> Telegram</span>
      </Nav.Link>
      {/* <Nav.Link href="https://www.reddit.com/r/rebase" className="text-decoration-none">
        <FaReddit />
        <span className="d-sm-none"> Reddit</span>
      </Nav.Link> */}
    </Nav>
  );
};

export default SocialNav;
